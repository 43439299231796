<template>
    <div class="bg" id="pg-index">
  
      <div class="topBox">
        <div class="leftC">
          <!-- <img src="@/assets/login/logo.png" width="50" height="50"> -->
          <p>{{ company.compName }}<br/></p>
        </div>
        <ul class="centerC">
          <li class="checked">
             <img src="@/assets/login/icon7.png" width="20" height="20" class="img_gray">
           
            <img src="@/assets/login/icon6.png" width="20" height="20" class="img_color">
            <p>供应商信息</p>
            <label>EIM</label>
          </li>
        </ul>
        <Dropdown class="rightC" trigger="click" @on-click="handleClickUserDropdown">
          <span :push="15">{{ company.compName }}</span>
          <Icon type="ios-arrow-down"></Icon>
          <DropdownMenu slot="list">
            <DropdownItem   name="edit">修改密码</DropdownItem>
            <DropdownItem name="exit">退出</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
  
  
      <ul class="tab">
        <li @click="selectChoices(1)" :class="activeName == 1?'active':''" >账号属性/ID info</li>
        <li @click="selectChoices(2)" :class="activeName == 2 ?'active':''">添加车牌信息/add plate</li>
        <li @click="selectChoices(3)" :class="activeName == 3 ?'active':''">过磅记录查询/query list</li>
        <li @click="selectChoices(4)" :class="activeName == 4 ?'active':''">司机扫码记录/scan qrcode</li>
   
      </ul>
  
      <ul class="tab-list">
        <li v-if="activeName == 1">
            <company-info :company="company" :companyUser="companyUser" v-if="activeName == 1"/>
        </li>
        <li v-if="activeName == 2">
              <plate-info :company="company" :companyUser="companyUser"  v-if="activeName == 2 "/>
        </li>
  
        <li v-if="activeName == 3">
              <query-list :company="company" :companyUser="companyUser"  v-if="activeName == 3 "/>
        </li>

        
        <li v-if="activeName == 4">
              <driver-scan :company="company" :companyUser="companyUser"  v-if="activeName == 4 "/>
        </li>
      </ul>
  

    
      <el-dialog append-to-body  title="修改密码" :visible.sync="passWordDiaLog" width="476px" >
      <div :style="{maxHeight: maxHeight}" class="drawer-content">
        <Form ref="searchForm" inline :label-width="70" class="search-form">
          <FormItem label="原密码">
            <Input type="password" placeholder="请输入原密码" v-model="oldPassword"
                   style="width: 350px"/>
          </FormItem>
          <FormItem label="新密码">
            <Input type="password" placeholder="请输入新密码" v-model="newPassword1"
                   style="width: 350px"/>
          </FormItem>
          <FormItem label="重新输入">
            <Input type="password" placeholder="请确认新密码"  v-model="newPassword2"
                   style="width: 350px"/>
          </FormItem>
        </Form>
      </div>
      <div class="drawer-footer br" style="text-align:right" slot="footer" >
        <el-button  type="primary" :loading="passWordLoadingModal" @click="submitPassWord">立即修改</el-button>
        <el-button @click="passWordDiaLog = false">取消</el-button>
      </div>
    </el-dialog>
  
  
    </div>
  </template>
  
  <script>
  // 根据你的实际请求api.js位置路径修改
  import {
    companyLogin,
    editCompanyPassWord
  } from "@/api/index";
  // 根据你的实际添加编辑组件位置路径修改
  import {router} from "@/router";
  import Cookies from "js-cookie";
  import CompanyInfo from "./CompanyInfo.vue";
  import PlateInfo from "./PlateInfo.vue";
  import QueryList from "./QueryList.vue";
  import DriverScan from "./DriverScan.vue";
  export default {
    name: "companyEmployee",
    components: {
      CompanyInfo,
      PlateInfo,
      QueryList,
      DriverScan
    },
    data() {
      return {
        activeName:1,
        height: 600,
        companyUser:{},
        company:{},
      
        passWordLoadingModal:false,
        passWordDiaLog:false,
        newPassword1:null,
        newPassword2:null,
        oldPassword:null,

      };
    },
    methods: {
      
      checkLoginInfo(){
        var companyUser = this.getCompanyUser()
        var company = this.getCompany()
        if (!companyUser || !company) {
          router.push('/login');
          return;
        } else {
          this.companyUser = companyUser
          this.company = company
        }
  
        companyLogin({
          username:this.companyUser.userName,
          password:this.companyUser.passWord,
          checkLogin:true}).then(res =>{
          if (!res.success){
            Cookies.set('company', null)
            Cookies.set('companyUser', null)
             router.push('/login');
          }
        }).catch(res=>{
            Cookies.set('company', null)
            Cookies.set('companyUser', null)
            router.push('/login');
        })
  
  
      },
   

 
      handleClickUserDropdown(name) {
        if (name == "edit") {
          this.passWordDiaLog = true
          this.newPassword1 = ''
          this.newPassword2 = ''
          this.oldPassword = ''
        } else if (name = 'exit') {
          this.exitLogin()
        }
      },
      exitLogin() {
        this.$Modal.confirm({
          title: "确认退出",
          content: "您确认要退出吗?",
          loading: false,
          onOk: () => {
            Cookies.set('company', null)
            Cookies.set('companyUser', null)
            router.push('/login');
            return;
          }
        });
  
  
      }, editPassword() {
        this.passWordDiaLog = true
        this.newPassword1 = ''
        this.newPassword2 = ''
        this.oldPassword = ''
      }, submitPassWord() {
        this.passWordLoadingModal = true
  
        const oldPassword = this.oldPassword
        const newPassword1 = this.newPassword1
        const newPassword2 = this.newPassword2
  
        if (newPassword1 == '') {
          this.$Message.error("密码不能为空！");
          this.passWordLoadingModal = false
          return
        }
        if (newPassword1 != newPassword2) {
          this.$Message.error("密码不一致！");
          this.passWordLoadingModal = false
          return
        } else if (oldPassword == newPassword1) {
          this.$Message.error("旧密码和新密码不能一样");
          this.passWordLoadingModal = false
          return
        }
  
        //update

        editCompanyPassWord({
          username:this.companyUser.userName,
          oldPassWord:oldPassword,
          newPassWord:newPassword1        
        }).then(res => {
          this.passWordLoadingModal = false;
          if (res.success) {
            this.$notify.success("修改成功")
            Cookies.set('company', null)
            Cookies.set('companyUser', null)
            router.push('/login');
            return;
          }
        });
  
  
      },
      selectChoices(v) {
        this.activeName = v
      },
      agreeChange(status) {
        this.switch1 = status;
      },
      init() {
     
        this.checkLoginInfo()
      },



     
    },
    mounted() {
      this.init();
      this.timer = setInterval(this.checkLoginInfo, 60000);
      window.onresize = () => {
      return (() => {
        this.height = (window.innerHeight)
      })()
    }
  }
  };
  </script>
  
  <style>
  .ivu-table-header th {
    color: white;
    background-size: cover;
    border: none;
    color: #aaa;
    font-size: 12px;
  }
  .ivu-table {
    width: inherit;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    color: #aaa;
    font-size: 12px;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ivu-table td {
    -webkit-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
    border-bottom: none !important;
  }
  
  
  .ivu-table:before{content:'';width:100%;height:0px;position:absolute;left:0;bottom:0;z-index:1}
  .ivu-table:after{content:'';width:0px;height:100%;position:absolute;top:0;right:0;z-index:3}
  
  .ivu-table-stripe-even td {
    background-color: #434343 !important;
    color: #aaa;
    font-size: 12px;
  }
  
  /*奇数行*/
  .ivu-table-stripe-odd td {
    background-size: cover;
    color: #aaa;
    font-size: 12px;
  }
  
  .ivu-select-selected-value {
    color: #aaa;
    font-size: 12px;
    background: none;
  }
  
  </style>
  
  <style scoped>
  

  

  
  .clear {
    clear: both;
  }
  
  .hidden {
    overflow: hidden;
  }
  

  ul {
    margin: 0;
    padding: 0;
  }
  
  ul li {
    list-style: none;
  }
  
  dl dd {
    margin: 0;
  }
  
  #login {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 300px;
    margin-left: -150px;
  }
  
  #login .logo {
    width: 140px;
    margin: 0 auto 30px;
  }
  
  #login ul li {
    margin-bottom: 15px;
  }
  
  #login ul li input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 14px;
  }
  
  #login .labelMain {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  
  #login .labelMain label {
    color: #f0f0f0;
    font-size: 12px;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  #login .labelMain input[type="checkbox"] {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
    line-height: 14px;
  }
  
  
  #login .labelMain input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #d9d9d9;
  }
  
  #login .labelMain input[type="checkbox"]:checked::before {
    content: "\2713";
    background-color: #cfae82;
    
    font-weight: bolder;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px solid #cfae82;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  
  #login button {
    margin-top: 30px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 0;
    outline: none;
    color: #fff;
    font-size: 14px;
    background-color: #cfae82;
    cursor: pointer;
  }
  
  #pg-index .displayB {
    display: block !important;
  }
  
  #pg-index .alertNav {
    position: fixed;
    background-color: #fff;
    text-align: center;
    z-index: 99;
    right: 20px;
    width: 100px;
    color: #333;
    font-size: 12px;
    top: 60px;
    display: none;
  }
  
  #pg-index .alertNav::after {
    content: '';
    position: absolute;
    top: -6px;
    right: 46px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  
  #pg-index .alertNav p {
    margin: 0;
    padding: 0;
    height: 30px;
    line-height: 30px;
  }
  
  #pg-index .topBox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 10px 1%;
    align-items: center;
    justify-content: space-between;
    z-index: 119;
  }
  
  #pg-index .topBox .leftC {
    display: flex;
    align-items: center;
    color: #f5f5f5;
    font-size: 12px;
    width: 240px;
  }
  
  #pg-index .topBox .leftC p {
    margin-left: 5px;
  }
  
  #pg-index .topBox .rightC {
    font-size: 12px;
    width: 200px;
    text-align: right;
    cursor: pointer;
    padding-right: 40px;
    position: relative;
    font-weight: 200;
  }
  
  #pg-index .topBox .centerC {
    color: #aaa;
    font-size: 12px;
    display: flex;
    align-items: center;
    list-style: none;
  }
  
  #pg-index .topBox .centerC li {
    text-align: center;
    margin: 0 30px;
    cursor: pointer;
  }
  
  #pg-index .topBox .centerC li .img_gray {
    display: block;
    margin: 0 auto;
  }
  
  #pg-index .topBox .centerC li .img_color {
    display: none;
    margin: 0 auto;
  }
  
  #pg-index .topBox .centerC li p {
    margin: 5px 0 0;
  }
  
  #pg-index .topBox .centerC .checked {
    color: #cfae82;
    font-weight: bolder;
  }
  
  #pg-index .topBox .centerC .checked .img_gray {
    display: none;
    margin: 0 auto;
  }
  
  #pg-index .topBox .centerC .checked .img_color {
    display: block;
    margin: 0 auto;
  }
  
  #pg-index .tab {
    position: fixed;
    top: 77px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    z-index: 9;
  }
  
  #pg-index .tab li {
    margin: 0 50px;
    font-size: 14px;
    cursor: pointer;
  }
  
  #pg-index .tab .active {
    color: #cfae82;
    font-weight: bolder;
  }
  
  #pg-index .tab-list {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  
  #pg-index .tab-list li {
    padding-top: 115px;
  }
  
  #pg-index .info {
    text-align: center;
    margin-top: 5%;
  }
  
  #pg-index .info .info_img {
    width: 200px;
    margin: 0 auto;
  }
  
  #pg-index .info .info_name {
    font-size: 16px;
    color: #cfae82;
    font-weight: bolder;
    margin: 20px 0;
  }
  
  #pg-index .info .info_dl {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #cfae82;
    line-height: 28px;
    font-weight: bolder;
  }
  
  #pg-index .info .info_dl span {
    color: #cfae82;
    font-weight: bolder;
    margin-left: 10px;
  }
  
  #pg-index .search {
    padding: 10px 1%;
    display: flex;
    color: #aaa;
    align-items: center;
    font-size: 14px;
  }
  
  #pg-index .search label {
    margin-right: 15px;
  }
  
  #pg-index .search label input,
  #pg-index .search label select {
    margin-left: 15px;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 0;
    text-indent: 10px;
    font-size: 14px;
    color: #fff;
  }
  
  #pg-index .search #chooseTime {
    background: url('../../../assets/login/data.png') rgba(0, 0, 0, 0.2) no-repeat center center;
    background-size: 18px 12px;
    background-position: center right;
  }
  
  #pg-index .search .btn {
    width: 60px;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    color: #aaa;
    line-height: 30px;
    border: 1px solid #aaa;
    cursor: pointer;
  }
  
  #pg-index .list {
    display: flex;
    padding: 12px 1%;
    font-size: 12px;
    color: #aaa;
    margin: 0;
  }
  
  .list {
    display: flex;
    padding: 12px 1%;
    font-size: 12px;
    color: #aaa;
    margin: 0;
  }
  
  #pg-index .list .li1 {
    width: 8%;
  }
  
  #pg-index .list .li2 {
    width: 5%;
  }
  
  #pg-index .list .li3 {
    width: 12%;
  }
  
  #pg-index .list .li4 {
    width: 20%;
  }
  
  #pg-index .list .li5 {
    width: 20%;
  }
  
  #pg-index .list .li6 {
    width: 20%;
  }
  
  #pg-index .list .li7 {
    width: 15%;
  }
  
  #pg-index .list .li8 {
    width: 5%;
  }
  
  #pg-index .list .li8 span {
    border: 1px solid #ccc;
    padding: 2px 5px 3px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #pg-index .list_con .list {
  }
  
  #pg-index .list_con .list:nth-child(odd) {
  }
  
  #pg-index .fenye {
    color: #aaa;
    text-align: right;
    font-size: 14px;
    margin-top: 30px;
  }
  
  #pg-index .fenye span {
    display: inline-block;
    padding: 0 10px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  #pg-index .fenye .nowPg {
    color: #cfae82;
    font-weight: bolder;
  }
  .flex{
  display: flex;
}
.cTitle{
  cursor: pointer;
  color:black;font-size:16px;font-weight: 800;
}
.btnAction{
  flex:1;text-align: right;
}
.vxe-pager.is--perfect .vxe-pager--num-btn:not(.is--disabled).is--active{
  background-color: #b6986d;
}

.is--checked.vxe-checkbox, .is--checked.vxe-checkbox .vxe-checkbox--icon, .is--checked.vxe-custom--option, .is--checked.vxe-custom--option .vxe-checkbox--icon, .is--checked.vxe-export--panel-column-option, .is--checked.vxe-export--panel-column-option .vxe-checkbox--icon, .is--checked.vxe-table--filter-option, .is--checked.vxe-table--filter-option .vxe-checkbox--icon, .is--indeterminate.vxe-checkbox, .is--indeterminate.vxe-checkbox .vxe-checkbox--icon, .is--indeterminate.vxe-custom--option, .is--indeterminate.vxe-custom--option .vxe-checkbox--icon, .is--indeterminate.vxe-export--panel-column-option, .is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon, .is--indeterminate.vxe-table--filter-option, .is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon, .vxe-table--render-default .is--checked.vxe-cell--checkbox, .vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon, .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox, .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon{
  color:#b6986d
}
  
  </style>
  