<template>
     <div class="info">
            <div class="info_img"><img src="@/assets/Certification.png" width="170" height="170"></div>
            <div class="info_name">账号 - {{companyUser.userName}}</div>
            <dl class="info_dl">
              <dd>供应商名称:<span>{{company.compName}}</span></dd>
              <dd>简称:<span>{{company.shortName}}</span></dd>
            </dl>
          </div>
  </template>
  
  <script>
  // 根据你的实际请求api.js位置路径修改
  import {
  
  
  } from "@/api/index";
  // 根据你的实际添加编辑组件位置路径修改
  import {shortcuts} from "@/libs/shortcuts";
  import {router} from "@/router";
  import {validateMobile, validatePassword} from "@/libs/validate";
  
  export default {
    name: "companyInfo",
    components: {},
    props:['company','companyUser'],
    data() {
      return {

        height: 600,
      }
    },
    methods: {
        init(){

        }
    },
    mounted() {
      this.init();
      window.onresize = () => {
      return (() => {
        this.height = (window.innerHeight)
      })()
    }
  }
  };
  </script>
  <style>
</style>